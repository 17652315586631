/* You can add global styles to this file, and also import other style files */
body{
  margin: 0px;
}
h1,h2,h3,h4,h5,p,span,div,b,strong,button{
  font-family: 'Inter', sans-serif!important;
}
/* Importing Bootstrap SCSS file. */
@import 'animate.css/animate.min.css';
@import 'bootstrap/scss/bootstrap';
@import "~bootstrap/dist/css/bootstrap.css";
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~@fortawesome/fontawesome-free/css/v4-shims.min.css';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.min.css";
@import "primeicons/primeicons.css";
@import "./theme/primeng-custom.scss";
@import "quill/dist/quill.core.css";

body {
  ngb-carousel:focus-visible {
    outline: none!important;
  }
  --primary-color: #6646F9;
  .cursor-pointer{
    cursor: pointer;
  }
  .p-paginator{
    background-color: transparent;
  }
  .p-indeterminate{
    minusicon{
      display: none;
    }
  }
  .p-indeterminate.p-highlight{
    checkicon{
      svg{
        color: white!important;
      }
    }
  }
}


html, body { height: 100%; }
body {
  margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;
  h1{
    font-size: clamp(1.5rem , 2vw, 2.2rem);
    font-weight: 600;
  }
  p,span,a{
    font-size: clamp(0.8rem , 2vw, 1rem);
  }
  .font-w-100{
    font-weight: 100;
  }
  .font-w-200{
    font-weight: 200;
  }
  .font-w-300{
    font-weight: 300;
  }
  .font-w-400{
    font-weight: 400;
  }
  .font-w-500{
     font-weight: 500;
   }
  .font-w-600{
    font-weight: 600;
  }
  .font-w-700{
    font-weight: 700;
  }

}
.text-xxs{
  font-size: 0.5rem;
}
.text-xs{
  font-size: 0.7rem;
}
.text-s{
  font-size: 0.8rem;
}
.text-m{
  font-size: 1rem;
}
.text-l{
  font-size: 1.2rem;
}
.text-xl{
  font-size: 1.4rem;
}

mark,
.mark {
  padding: 0;
  //color: #6646F9;
  background-color: #ffdb67;
  font-weight: bold;
  border-radius: 4px;
}

.step2 , .categories-management{
    .p-inputtext:enabled:focus{
      box-shadow: 0 0 0 0.2rem #DED2F8;
      border-color: #DED2F8;
    }
    span {
      font-size: 0.9rem;
    }
    p-tree{

      .p-tree-container{

        .p-treenode-content{
          padding: 0.3rem!important;
          .p-tree-toggler{
            height: 1rem!important;
            width: 1rem!important;
          }
        }

      }

    }

    .p-treeselect  {
      .p-treeselect-label{
        display: flex;
        flex-direction: column;
        .p-treeselect-token{
          span{
            font-size: 0.9rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }


      }
      width: 100%;
    }
    p-treeselect{
      .p-treeselect:not(.p-disabled):hover {
        border-color: #6646F9;
      }
    }
  .p-dropdown{
    width: 100%;

  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: #6646F9;
  }
}
.form-licitacion-level-1{
  .p-treeselect-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none!important;
  }
  .p-treeselect-close:hover {
    background-color: transparent!important;
  }
  p-treenode{

    .p-treenode-label{
      font-weight: 500;
    }
  }
}
.card-selector-2-level{
  p-treenode{
    span{
      font-size: 0.95rem!important;
    }
    .p-highlight{
      background-color: #DED2F8!important;
      border-color: #6646F9;
      color: black!important;
      .p-checkbox-box{
        background-color: #6646F9!important;
        border-color: #6646F9;
      }
    }
    .p-treenode-label{
      font-weight: 600;
    }
    ul{
      .p-treenode-label{
        font-weight: 400!important;
      }
      .p-highlight{
        background-color: transparent!important;
        border-color: unset;
        .p-checkbox-box{
          background-color: #6646F9!important;
          border-color: #6646F9;
        }
      }
    }
  }
}
.p-tree.p-component.p-tree-selectable{
  //max-height: 400px;
  //overflow-y: scroll;
  background-color: transparent;
  border: none;
  padding: 5px;
  border-radius: 6px;
  font-size: 0.9rem;
}
.p-ripple.p-element.p-link.p-tree-toggler:focus{
  outline: none!important;
  box-shadow: none!important;
}
.p-ripple.p-element.p-link:focus{
  outline: none!important;
  box-shadow: none!important;
}
.register-page{
  .carousel-inner{
    overflow: initial;
  }
  .carousel-indicators{
   display: none;
  }
  .img-top{
    max-width: 200px;
  }

}
.purchase-page{
  .carousel-inner{
    overflow: initial;
  }
}
#custom-target {
  .swal2-popup.swal2-toast.succes-toast {
        background-color: #d4edda;
       max-width: 320px ;
      .swal2-icon.swal2-icon-show{
        border: none;
      }
      .swal2-html-container{
        color: rgb(58, 164, 107);
        font-weight: 600;
      }
    }
  .swal2-popup.swal2-toast.warning-toast{
    background-color: #fff3cd;
    max-width: 320px ;
    .swal2-icon.swal2-icon-show{
      border: none;
    }
    .swal2-html-container{
      color: #a4923a;
      font-weight: 600;
    }
  }
}
p-paginator{
  .p-paginator-pages {
    .p-ripple {
      background-color: #DED2F8!important;
      color: #717171!important;
    }
    .p-highlight{
      color: #6646F9!important;
      border-color: #6646F9!important;
      border-style: solid!important;
      border-width: 1px!important;
    }
    .p-highlight:focus{
      box-shadow: 0 0 0 0.2rem #c6b4ff;
    }
  }
  .p-dropdown-item.p-highlight{
      background-color: #eee7ff !important;
      color: #6646F9!important;
    }
  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #eee7ff!important;
    border-color: #6646F9!important;
  }
}
.home-wrapper {
  p-chip {
    .p-chip {
     background-color: transparent;
    }
  }
  .p-message-icon{
    display: flex;
  }
  .p-message .p-message-wrapper {
    padding: 1rem 1.75rem;
  }
}
asl-google-signin-button{
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
}

@media (min-width: 1400px){
  .container {
    max-width: 1200px;
  }
}
@media (max-width: 991px) {
  .p-overlay {
    width: 100%;
  }
  .p-message-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .btn-segment-filter{
    width: 100%;
  }
}
  .popover-body {
    i {
      width: 35px;
    }
    p:hover {
      i {
        color: #6646F9 !important;
      }
    }
  }
  .modal-profile-down{
    .modal-dialog {
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow-y: auto;
      min-height: calc(100vh - 60px);

    }
    .modal-body{
      background-color: #F8F4FF
    }
    @media (max-width: 767px) {
      .modal-dialog {
        min-height: calc(100vh - 20px);
      }


    }
    .modal-dialog{
      max-width: 500px;
    }
  }
  .community-selector{
    p-multiselectitem:first-child{
      li {
        //font-weight: 700 !important;
      }
    }
  }
  .category-selector{
    .p-treeselect-token{
      background-color: transparent!important;
      font-weight: 300;
      color: var(--primary-color)!important;
    }

    .licitations-style-lenght{
      .p-treeselect-token:after{
        content: var(--option-text-level-selected);
      }
    }
    .p-treeselect-token:not(:first-child){
      display:none;
    }
  }
.p-tree{
  .p-treenode .p-treenode-content.p-highlight{
    background-color: #DED2F8!important;
    color: black!important;
    .p-checkbox-box.p-highlight{
      background-color: #6646F9!important;
      border-color: #6646F9!important;
    }
  }

}
.p-multiselect-header .p-checkbox:after {
  content: var(--option-text-comunities);
  margin-left: 5px;
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
  width: auto;
}
.footer-fixed{
  background-color: #e9e5fd;
  position: fixed;
  width: inherit;
  left: 0;
  bottom: 0;
  z-index: 2;
  box-shadow: 0 -4px 3px rgb(0 0 0 / 6%), 0 3px 6px rgba(0,0,0,0.23);

}
.home-wrapper{
  .mobile{
    .p-paginator{
      flex-wrap: nowrap;
    }
  }
}
@media screen and (max-width: 991px) {
  .desktop{
    display: none!important;
  }
  .comunity-selector{
    .title-selector{
      font-size: 16px;
    }
    .p-multiselect{
      .p-multiselect-label.p-placeholder{
        font-size: 14px;
      }
      .p-multiselect-token-label{
        font-size: 12px!important;
      }
    }

  }
}
@media screen and (min-width: 992px) {
  .mobile{
    display: none!important;
  }
}
.default-layout{
  margin-top: 80px;
}

.box-right{
  .p-checkbox {
    .p-checkbox-box.p-highlight{
      background-color: #6646F9!important;
      border-color: #6646F9!important;
    }
  }
}
.inner-html{
  p{
    margin-bottom: 0!important;
  }
}
.modal-tag{
  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #DED2F8;
    border-color: #6646F9;
  }
}
.home-wrapper{
  .p-input-icon-left > .p-inputtext{
    padding-left: 0.5rem;
  }
}

  .carousel-indicators{
    display: none;
  }
.form-step-3 , .profile {
  .p-dropdown.p-component{
    width: 100%;
    .p-dropdown-label{
      padding: 0.5rem;
    }
  }
  .bg-error{
    .p-dropdown{
      background-color: #f8d7da;
      transition: background-color, 0.5s;
    }
  }
}
